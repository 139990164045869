@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './mixin';
@import './affix';
@import './allow-clear';

@input-prefix-cls: ~'@{ant-prefix}-input';

// Input styles
.@{input-prefix-cls} {
  .reset-component();
  .input();

  //== Style for input-group: input with label, with button or dropdown...
  &-group {
    .reset-component();
    .input-group(~'@{input-prefix-cls}');

    &-wrapper {
      display: inline-block;
      width: 100%;
      text-align: start;
      vertical-align: top; // https://github.com/ant-design/ant-design/issues/6403
    }
  }

  &-password-icon {
    color: @text-color-secondary;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: @input-icon-hover-color;
    }
  }

  &[type='color'] {
    height: @input-height-base;

    &.@{input-prefix-cls}-lg {
      height: @input-height-lg;
    }
    &.@{input-prefix-cls}-sm {
      height: @input-height-sm;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  &-textarea {
    &-show-count::after {
      float: right;
      color: @text-color-secondary;
      white-space: nowrap;
      content: attr(data-count);
      pointer-events: none;
    }
  }
}

@import './search-input';
@import './rtl';
@import './IE11';
